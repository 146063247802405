$forMobile: "true";@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

.bestPricePromiseEyeCatcher {
    padding-top: $global-spacing-small;
    background-color: $almostWhite;
}

.contents {
    padding: $global-spacing 10px;
    background-color: $sophieWhite;
}
