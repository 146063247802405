$forMobile: "true";@import 'Application/view/generated/colors';
@import 'Application/view/generated/assets/assets';
@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/zIndices';
@import 'Application/view/homes/css-components/variables';
@import 'Application/view/search-form/components/tooltips/searchFormTooltipContainer';

.destinationSuggestionsContainer {
    @include searchFormToolTipContainer;

    display: none;
    position: absolute;
    width: 100%;
    font-size: 14px;
    z-index: zIndex(partialOverlays) + 2;
    top: 100%;
    margin-top: 0;

    &__open {
        display: block;
    }
}

.suggestionsList {
    margin-left: 0;
    margin-bottom: 0;
}
