$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/zIndices';
@import 'Application/view/framework/common/mixins/selectNone';
@import 'Application/view/generated/colors';
@import 'react-toastify/dist/ReactToastify.css';

.Toastify {
    --toastify-z-index: #{zIndex(wireframeOverlays) + 1};

    z-index: zIndex(overlays) + 1;

    .Toastify__toast {
        @include selectNone;

        min-height: auto;
        padding: 12px;
        font-size: 14px;
        border-radius: $global-radius;
        background: $sophieWhite;
        color: $silverStar;
        margin-left: 10px;
        margin-right: 10px;

        &.Toastify__toast-theme--dark {
            background: rgba($effiEffiSteffi, 0.9);
            color: $sophieWhite;

            .Toastify__close-button {
                color: $sophieWhite;
            }
        }

        &.Toastify__toast--success {
            background-color: $brat;
            color: $sophieWhite;

            .Toastify__close-button {
                color: $sophieWhite;
            }
        }
    }

    .Toastify__toast-body {
        padding: 0;
    }

    .Toastify__close-button {
        height: 21px;

        & > svg {
            width: 21px;
            height: 21px;
        }
    }
}
