$forMobile: "true";@import 'Application/view/framework/common/settings';

.sampleCalculationMobile {
    font-size: 16px;
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sampleCalculation {
    margin-bottom: $global-spacing;
}

.totalSaving {
    color: $chatbotBaumGreen;
    align-self: center;
    text-align: center;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
}

.text {
    white-space: break-spaces;
    margin-bottom: $global-spacing;
}

.row {
    display: flex;
}

.marker {
    height: 100%;
    margin-right: $global-spacing-small;
}

.providerColumn {
    margin-right: $global-spacing-small;
}
