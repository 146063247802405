$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.sectionSplit {
    margin: $global-spacing-medium 0;
    border-bottom: 1px solid $marktMaedels;

    &__smallerMargin {
        margin: $global-spacing-smedium 0;
    }

    &__mobile {
        margin: $global-spacing 0;

        &.sectionSplit__smallerMargin {
            margin: $global-spacing-smedium 0;
        }
    }
}
