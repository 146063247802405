$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.customNativeSelect {
    display: block;
    position: relative;
}

.select {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: 0;
    overflow: hidden;
    opacity: 0;
    appearance: none;
    background: $sophieWhite;
    border: 1px solid $plainGrey;
    border-radius: 3px;
    color: $silverStar;
    font-size: 16px;
    line-height: 1.5;
    padding: 10px 24px 10px 14px;
    transition:
        box-shadow 500ms,
        border-color 250ms ease-in-out;

    :-ms-expand {
        display: none;
    }
}
