$forMobile: "true";@import 'Application/view/framework/common/settings';

.content {
    padding: $global-spacing;
    max-width: 98vw;

    &__noHorizontalPadding {
        padding: $global-spacing 0;
    }
}
