$forMobile: "true";@import 'Application/view/css-components/searchformVariables';
@import 'Application/view/generated/colors';

.destinationSuggestionInputMobile {
    background-color: $sophieWhite;
    height: $searchform-input-height;
    font-size: $searchform-input-font-size;
    border-radius: $searchform-border-radius $searchform-border-radius 0 0;
    display: flex;
    align-items: center;
}
