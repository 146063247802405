$forMobile: "true";@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';
@import 'Icons/view/generated/icons';
@import 'Application/view/framework/common/mixins/quadrat';
@import 'Application/view/framework/common/mixins/selectNone';

$verticalPadding: 6px;
$horizontalPadding: 10px;
$deleteIconSpace: 18px;
$deleteIconSizeMobile: 16px;
$deleteIconOffset: -3px;
$addIconSpace: 16px;
$addIconSizeMobile: 16px;
$addIconOffset: -3px;
$borderSize: 1px;

.filterChip {
    @include selectNone;

    padding: $verticalPadding $horizontalPadding;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    vertical-align: bottom;
    background-color: $sophieWhite;
    color: $blueFire;
    border: $borderSize solid $blueFire;
    border-radius: 3px;
    transition: border-color 0.25s ease-in-out;
    height: calc(#{($verticalPadding + $borderSize) * 2} + #{$global-line-height}em);

    &:after {
        color: $blueFire;
    }

    &.filterChip__withHoverEffect {
        &:hover {
            background-color: $paleBlue;
            border-color: $blueFire;
        }
    }

    &__mobile {
        border-color: $blueFire;
        color: $blueFire;
        border-radius: 20px;
        height: 29px;

        &.filterChip__isActivated {
            color: $silverStar;
        }

        .filterContent {
            font-size: 14px;
        }
    }

    &__isActivated {
        position: relative;
        background-color: $frosteon;
        color: $silverStar;
        border-color: $blueFire;
        padding-right: $horizontalPadding + $deleteIconSpace;

        &.filterChip__withHoverEffect {
            &:hover {
                background-color: $paleBlue;
            }
        }

        &.filterChip__withDeleteIcon {
            &.filterChip:after {
                content: $notAvailableIcon;

                @include iconFont;

                position: absolute;
                right: $horizontalPadding + $deleteIconOffset;
                font-size: 17px;
            }

            &.filterChip__mobile {
                padding-right: $horizontalPadding * 2 + $deleteIconSizeMobile;

                &:after {
                    font-size: 10px;
                    right: $horizontalPadding;
                    line-height: $deleteIconSizeMobile;

                    @include quadrat($deleteIconSizeMobile);

                    background-color: rgba($blueFire, 0.1);
                    border-radius: 50%;
                    text-align: center;
                }
            }
        }
    }

    &__isDisabled {
        position: static;
        background-color: $alabaster;
        border-color: $marktMaedels;
        color: $plainGrey !important; // using '!important' to force disabled elements styling (gray text-color) over applied device/product modifiers
        cursor: default;

        &.filterChip__withHoverEffect {
            &:hover {
                background-color: $alabaster;
                border-color: $marktMaedels;
            }
        }

        &:after {
            color: $plainGrey;
        }
    }

    &__isCrawlS4 {
        background-color: $deutziaWhite;
        color: $blueStone;
        border: $borderSize solid $blueStone;
        border-radius: 10px;
        height: 48px;
        font-size: 14px;
    }
}
