$forMobile: "true";@import 'Application/view/generated/colors';

.button {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.backgroundContainer {
    position: absolute;
    width: 50%;
    height: 100%;
    background-color: $frosteon;
    z-index: 0;
}
