$forMobile: "true";@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

.productSwitchMobile {
    display: flex;
    margin: 0 5px;
    gap: $global-spacing-smedium;

    &__reverse {
        flex-direction: row-reverse;
    }
}

.toggle {
    flex: 1;
    font-size: 16px;
    border-bottom: 2px solid $marktMaedels;
    text-align: center;
    padding: $global-spacing-xsmall 0 10px;

    &__active {
        border-color: $silverStar;
        font-weight: bold;
    }
}
