$forMobile: "true";@import 'Application/view/generated/colors';

.loadingComponentSpinner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
    background: $sophieWhite;
}
