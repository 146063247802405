$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/framework/common/mixins/mixins';

.bookingActionBarShowInAppTooltip {
    display: flex;
    row-gap: $global-spacing;
    flex-direction: column;
    padding: $global-spacing;
}

.title {
    font-weight: bold;
    font-size: 14px;
}

.choiceContainer {
    display: flex;
    column-gap: $global-spacing;
}

.separator {
    border-left: 1px solid $grey;

    &:first-child,
    &:last-child {
        display: none;
    }
}

.qrCode {
    height: 140px;
    cursor: pointer;
}

.smsForm {
    flex: 1;
}

.appTeaser {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.appTeaserButtons {
    margin-top: $global-spacing;
    display: flex;
}

.tooltipTrigger {
    line-height: 16px;
}
