$forMobile: "true";@import './stateBox';

.visualizedState {
    @include stateBox;
}

.header {
    @include stateBoxHeader;
}

.properties {
    padding: $stateBoxPadding;
}
