$forMobile: "true";.radioButtonLabel {
    font-size: 14px;

    &__mobile {
        font-size: 18px;
    }
}

.box {
    width: 100%;
}
