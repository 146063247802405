$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/generated/zIndices';
@import 'Application/view/framework/common/arrow';
@import 'Application/view/framework/common/mixins/selectNone';

$arrowSize: 30px;

.verticalDayPickerPopUp {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: zIndex(overlays);
    background: rgba($darkSideOfTheForce, 0.5);

    &__wireframeZIndex {
        z-index: zIndex(wireframeElements);
    }
}

.contents {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 0 auto;
}

.transparentSpace {
    flex: 1 0 140px;
    position: relative;
}

.weekdays {
    background: $lightGrey;
    flex: 1 0 35px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: $doveGrey;
    font-size: 14px;

    @include selectNone;
}

.dayPicker {
    position: relative;
    overflow-y: scroll;

    // Need to explicity enable smooth scrolling on iOS
    -webkit-overflow-scrolling: touch;
    background: $body-background;
}

.footer {
    z-index: zIndex(contentElements);
    padding: 10px;
    background-color: $sophieWhite;
    border-top: 1px solid $lightGrey;
}

.popUp {
    text-align: center;
}

.okButton {
    display: flex;
    justify-content: center;
    margin: $global-spacing-small 0;
}

.dateHint {
    text-align: center;
    font-size: 16px;
    padding-bottom: $global-spacing-small;

    & > span,
    & > strong {
        white-space: nowrap;
    }
}

.closeIcon {
    position: absolute;
    top: 100px;
    right: $global-spacing;
    font-size: 25px;
    color: $sophieWhite;
}
