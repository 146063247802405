$forMobile: "true";@import 'Application/view/framework/common/settings';

.headline {
    margin: $global-spacing 0;
    font-size: 20px;
    line-height: 1.2;
}

.content {
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: $global-spacing-small;

    &:last-of-type {
        margin-bottom: $global-spacing;
    }
}
