$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.container {
    padding: $global-spacing;
    max-width: $global-width;
    max-height: 90%;

    &__desktop {
        min-width: 450px;
        height: 500px;

        .header {
            padding: 0 $global-spacing $global-spacing;

            // Using minus margin on desktop, so the border below the headline uses the full overlay width.
            margin: 0 (-$global-spacing) $global-spacing;
        }
    }
}

.overlay {
    position: relative;
    background-color: $sophieWhite;
    border-radius: $global-radius;
    padding: $global-spacing;
}

.header {
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid $marktMaedels;
    color: $silverStar;
}
