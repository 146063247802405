$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/mixins';

.loginTeaserMobile {
    background-color: $buckIsBluetiful;
    padding: 10px 10px 20px;
    position: relative;
    border-radius: 7px;
}

.headline {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.icon {
    font-size: 48px;
    display: flex;
    line-height: 1;
    color: $sophieWhite;
    margin-right: 20px;
}

.title {
    font-size: 20px;
    line-height: 24px;
    color: $sophieWhite;
}

.advantages {
    display: flex;
    flex-direction: column;
    margin-bottom: $global-spacing-medium;
}
