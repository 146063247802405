$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.wireframeContactTooltipMobile {
    width: 100%;

    * {
        box-sizing: border-box !important;
    }
}
