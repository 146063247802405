$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/mixins';

.destination {
    // Note: This is enables crawlers & bot to see more content for initial hidden values but keeps the user view clean
    &__isHidden {
        display: none;
        visibility: hidden;
    }
}

.destinationLink {
    color: $silverStar;
    padding: 10px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.destinationLinkIcon {
    @include iconFont;

    color: $grey;
    font-size: 30px;
    line-height: 1;
}

.furtherDestinations {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        border-bottom: 1px solid $marktMaedels;
    }
}

.toggle {
    font-size: 14px;
    margin: $global-spacing-small 0 0;
    padding: 0 10px;
    display: flex;
    justify-content: flex-end;
}
