$forMobile: "true";@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

.container {
    padding: $global-spacing;
    text-align: center;
    border-top: 1px solid $marktMaedels;
    font-size: 14px;

    &__withMarginTop {
        margin-top: $global-spacing-smedium;
    }
}
