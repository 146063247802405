$forMobile: "true";@import 'Application/view/generated/zIndices';
@import 'Application/view/framework/common/settings';
@import 'Application/view/framework/common/mixins/safeAreaInset';

.toastifyContainerMobile {
    &.toastifyContainerMobile {
        @include safeAreaInset(bottom);

        padding-bottom: $global-spacing;
    }
}
