$forMobile: "true";.container {
    overflow: auto;

    // Hides scrollbar but div is still scrollable.
    &::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;

    &__disableScroll {
        overflow: hidden;
    }
}
