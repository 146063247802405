$forMobile: "true";@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

.promoBanner {
    background-color: $buckIsBluetiful;
    color: $sophieWhite;
    padding: $global-spacing;
    width: 100%;
}

.bannerTitle {
    font-size: 18px;
}

.bannerText {
    font-size: 14px;
    margin-bottom: 0;
}
