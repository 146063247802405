@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Icons/view/generated/icons';
@import 'Application/view/framework/common/mixins/mixins';

.mobileFrontpageLinks {
    display: block;
    padding: 0 10px;

    &__link {
        @include legacyIconFont;

        &:before {
            content: $arrowContourRightIcon;
            font-size: 32px;
            line-height: 24px;
            left: auto;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
            color: $grey;
        }

        display: block;
        font-size: 16px;
        line-height: 20px;
        padding: 17px 0;

        &:not(:last-child) {
            border-bottom: 1px solid $silver;
        }
    }
}
