@import 'Icons/view/generated/icons';

@mixin iconCheckedBefore {
    @include legacyIconFont;

    &:before {
        content: $checkIcon;
        left: 0;
        color: $brat;
        font-size: 16px;
    }

    &--small {
        &:before {
            font-size: 12px;
        }
    }
}

@mixin iconPhoneBefore {
    @include legacyIconFont;

    padding-left: 1.8rem;

    &:before {
        content: $phoneIcon;
        font-size: 1.8rem;
        left: 0;
    }
}
