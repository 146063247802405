$forMobile: "true";@import 'Application/view/generated/colors';

$componentHeight: 200px;
$slideWidth: 140px;

.imageContainer {
    background: linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 50%));
    border-radius: 4px;
}

.destination {
    position: relative;
    object-fit: cover;
    border-radius: 4px;
    width: $slideWidth;
    height: $componentHeight;
    z-index: -1;
}

.destinationInfo {
    position: absolute;
    width: $slideWidth;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    word-break: break-word;

    // The destinationPriceInfo will be 24px high and we need to be 16px above it
    bottom: 72px;
    color: $sophieWhite;
    font-size: 12px;
    z-index: 1;
}

.destinationHeadline {
    display: block;
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
}
