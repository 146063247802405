$forMobile: "true";@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/hideScrollbar';

.travelToggles {
    display: flex;
    overflow: auto;
    height: 55px;
    padding: 10px;
    font-size: 14px;
    background: $buckIsBluetiful;

    @include hideScrollbar;
}
