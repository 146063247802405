$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.textButton {
    color: $blueFire;

    &:hover {
        cursor: pointer;
    }

    // We need to explicitly set this on default to none. The wireframe styles
    // give every a-tag an underline on hover. The foundation resets normally
    // would overwrite this, but alas it only sets the :hover style, when it
    // differs from the default style.. which it doesn't..
    // See "Links" in node_modules/foundation-sites/scss/typography/_base.scss
    &:hover,
    &:focus {
        text-decoration: none;
    }

    &__bold {
        font-weight: bold;
    }

    &__isDisabled {
        &,
        &:hover {
            cursor: default;
            color: $dustyGrey;
        }
    }

    &__underlineOnHover.textButton__mouseDevice {
        &:hover,
        &:focus {
            text-decoration: underline;
            outline: none;
        }
    }

    &__orangeTextOnHover.textButton__mouseDevice {
        &:hover,
        &:focus {
            color: $juna;
            outline: none;
        }
    }

    &__alwaysUnderline {
        text-decoration: underline;
    }
}
