$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.appInstallTeaserBox {
    position: relative;
    border: 1px solid $marktMaedels;
    border-radius: $global-radius;
    font-size: 14px;
    margin-bottom: $global-spacing;
}

.appInstallTeaser {
    padding: 10px 0;
}
