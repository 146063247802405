$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/textOverflowEllipsis';

$componentWidth: 250px;

.tinyResultMobile {
    position: relative;
}

.image {
    width: $componentWidth;
    height: 140px;
    background-size: cover;
    background-position: center;
    position: relative;
    border-radius: $global-radius;

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 35%;
        background: linear-gradient(to top, rgba($darkSideOfTheForce, 0.5), rgba($darkSideOfTheForce, 0));
        border-radius: $global-radius;
    }
}

.supplierLogoContainer {
    position: absolute;
    left: 8px;
    bottom: 8px;
}

.title {
    @include textOverflowEllipsis;

    font-size: 16px;
    line-height: 1.2;
    font-weight: bold;
    color: $silverStar;
    margin-top: $global-spacing-small;
    max-width: $componentWidth;

    &:last-child {
        margin-bottom: $global-spacing-xsmall;
    }
}

.price {
    position: absolute;
    bottom: 8px;
    right: 8px;
    color: $sophieWhite;
    font-size: 16px;
    font-weight: bold;
}

.ratingContainer {
    margin-top: $global-spacing-small;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    height: 42px;
    row-gap: $global-spacing-small;

    &__nowrap {
        flex-flow: row;
        justify-content: flex-start;
        height: auto;
        column-gap: $global-spacing-small;
    }
}

.noStarRatingContainer {
    margin-top: $global-spacing-small;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    row-gap: $global-spacing-small;

    &__nowrap {
        flex-flow: row;
        justify-content: flex-start;
        column-gap: $global-spacing-small;
    }
}

.stars {
    display: flex;
    align-items: center;
}

.rating {
    display: flex;
    align-items: center;
    column-gap: $global-spacing-xsmall;
}

.formattedRating {
    border-radius: 5px 5px 0;
    padding: 0 $global-spacing-xsmall;
    height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 14px;
    line-height: 1;
    color: $sophieWhite;
    background-color: $buckIsBluetiful;
}

.ratingCount {
    color: $silverStar;
    font-size: 14px;
    line-height: 1;
}

.cityName {
    @include textOverflowEllipsis;

    font-size: 14px;
    color: $silverStar;

    &:last-child {
        margin-bottom: $global-spacing-xsmall;
    }
}

.distanceCity {
    font-size: 14px;
    color: $dustyGrey;
    display: flex;
    align-items: center;
    column-gap: $global-spacing-xsmall;
}

.markerIcon {
    font-size: 14px;
    line-height: 14px;
}
