$forMobile: "true";@import 'Application/view/generated/colors';
@import 'Application/view/generated/assets/assets';
@import 'Application/view/framework/common/settings';
@import 'Application/view/framework/common/mixins/mixins';

.hint {
    display: flex;
    align-items: center;
    background-color: $sophieWhite;
    color: $darkSideOfTheForce;
    padding: $global-spacing-small;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: zIndex(wireframeElements);
    column-gap: $global-spacing-small;
}

.headline {
    font-weight: bold;
    font-size: 14px;
}

.text {
    font-size: 13px;
}

.image {
    background-image: url($check24AppIconPng);
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    background-size: cover;
    border-radius: $global-radius;
}

.content {
    flex-grow: 1;
}

.icon {
    color: $blueFire;
    font-size: 30px;
    line-height: 20px;
    display: flex;
    width: 14px;
}
