$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/generated/zIndices';

.dayPickerWrapper {
    margin: 0 10px;
    display: flex;
    flex-direction: column;
}

.dayPickerMonthCaption {
    padding-top: $global-spacing;
    padding-bottom: $global-spacing-small;
    text-align: center;
    font-size: 16px;
    font-weight: bold;

    /**
    * height is set explicitly to prevent layout shift on iOS
    *   24px (padding-top + padding-bottom)
    * + 24px (font-size: 16px * 1.5 line-height)
    */
    height: 48px;
}

.dayPickerTable {
    width: 100%;
}

.dayPickerWeekRow {
    display: flex;
    margin: 3px 0;
}

.dayPickerDayContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    position: relative;
}

/**
 * React-day-picker will also render containers for days of
 * the previous and the following month (in the first and last
 * week respectively). And those day containers will also
 * receive those modifieres from above, so we need to undo the
 * styling changes for those days.
 */
.dayPickerOutsideMonthDay {
    background-color: transparent;
}

.dayPickerDay {
    height: 35px;
    display: flex;
    flex: 0 0 40px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: $silverStar;

    &__today {
        font-weight: bold;
    }

    &__arrival,
    &__arrivalOnly,
    &__departure {
        background-color: $blueFire;
        color: $sophieWhite;
        border-radius: 4px;
        z-index: zIndex(contentElements);
    }

    &__inBetween {
        background-color: $frosteon;
        z-index: 1;
    }

    &__disabled {
        color: $marktMaedels;
    }
}
