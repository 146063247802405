$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/outlineNone';
@import 'Application/view/framework/common/mixins/textOverflowEllipsis';

// Questionable hack to overcome global resets
.button.button {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 $global-spacing;
    padding: 0.85em 1em;
    border: 1px solid;
    border-radius: 3px;
    transition:
        background-color 0.25s ease-out,
        color 0.25s ease-out,
        border 0.25s ease-out;
    font-family: $body-alternative-font-family;
    appearance: none;
    line-height: 1;
    cursor: pointer;
    text-align: center;

    @include outlineNone;

    &:hover {
        text-decoration: none;
    }

    &__styleType_default {
        &,
        &:focus {
            color: $sophieWhite;
            background-color: $werkiWilkeBlue;
            border-color: transparent;
        }

        &.button__mouseDevice:hover {
            background-color: $endeavour;
        }
    }

    &__styleType_secondary {
        &,
        &:focus {
            color: $doveGrey;
            background-color: $sophieWhite;
            border: 1px solid $grey;
        }

        &.button__mouseDevice:hover {
            background-color: $alabaster;
        }
    }

    &__styleType_alert {
        &,
        &:focus {
            color: $sophieWhite;
            background-color: $ersterFussballClubKoeln;
            border-color: transparent;
        }

        &.button__mouseDevice:hover {
            background-color: darken($ersterFussballClubKoeln, 10%);
        }
    }

    &__styleType_inverted {
        &,
        &:focus {
            color: $blueFire;
            border-color: $blueFire;
            background: none;
        }

        &.button__mouseDevice:hover {
            background-color: $frosteon;
        }
    }

    &__styleType_invertedWhite {
        &,
        &:focus {
            color: $blueFire;
            border-color: $blueFire;
            background-color: $sophieWhite;
        }

        &.button__mouseDevice:hover {
            background-color: $frosteon;
        }
    }

    &__styleType_white {
        &,
        &:focus {
            color: $sophieWhite;
            border-color: $sophieWhite;
            background-color: $buckIsBluetiful;
        }

        &.button__mouseDevice:hover {
            background-color: $blueFire;
        }
    }

    &__styleType_invertedWhiteFont {
        &,
        &:focus {
            color: $sophieWhite;
            border-color: $sophieWhite;
            background: none;
        }

        &.button__mouseDevice:hover {
            background-color: $lapisBlue;
        }
    }

    &__disabled,
    &[disabled] {
        cursor: auto;
        opacity: 0.5;

        &.button__styleType_default {
            &,
            &:hover,
            &:focus {
                background-color: $silver;
            }
        }

        &.button__styleType_inverted {
            &,
            &:hover,
            &:focus {
                color: $doveGrey;
                border-color: $grey;
            }
        }

        &.button__styleType_invertedWhite {
            &,
            &:hover,
            &:focus {
                color: $doveGrey;
                border-color: $grey;
                background-color: $sophieWhite;
            }
        }

        &.button__styleType_secondary {
            &,
            &:hover,
            &:focus {
                color: $doveGrey;
            }
        }

        &.button__styleType_alert {
            &,
            &:focus,
            &:hover {
                border-color: lighten($ersterFussballClubKoeln, 30%);
                background-color: lighten($ersterFussballClubKoeln, 30%);
            }
        }
    }

    &__expanded {
        display: block;
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }

    &__size_small {
        font-size: 12px;
    }

    &__size_medium {
        font-size: 14px;
    }

    &__size_default {
        // Yeah, the default size on desktop is the same as medium.
        // But since that size is also used on mobile, we can't
        // get rid of it.
        font-size: 14px;

        // And according to the style guide the default button on
        // mobile must have a font-size of 20px and a height of
        // 50px (which is achieved with the vertical padding).
        &.button__mobile {
            font-size: 20px;

            &:not(.button__noPadding) {
                padding: 14px 10px;
            }
        }
    }

    &__size_large {
        font-size: 16px;
    }

    &__size_veryLarge {
        font-size: 18px;
    }

    &__size_extraLarge {
        font-size: 20px;
    }

    &__slim {
        $padding: 0.5em;

        padding-top: $padding;
        padding-bottom: $padding;
    }

    &__fontBold {
        font-weight: bold;
    }

    &__noMargin {
        margin-bottom: 0;
    }

    &__noPadding {
        padding: 0;
    }

    &__expandedVertically {
        height: 100%;
    }

    &__centered {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__preventWrap {
        &,
        .text {
            @include textOverflowEllipsis;
        }
    }

    .text {
        position: relative;
    }

    &.button__showSpinner {
        .spinner {
            display: block;
        }

        &.button__withSpinnerFix {
            padding-right: 40px;

            .text {
                display: flex;
                justify-content: center;
            }

            .content {
                position: relative;
            }
        }
    }

    .spinner {
        left: calc(100% + 10px);
        margin-left: 0;
        right: 15px;
        bottom: 50%;
    }

    // Buttons with dark backgrounds get a light spinner color
    &.button__styleType_default,
    &.button__styleType_alert {
        .spinner {
            border-color: rgba($sophieWhite, 0.4);
            border-bottom-color: $sophieWhite;
        }
    }

    // Users on mobile need shadows for their buttons
    &__mobile {
        box-shadow: 0 2px 3px 0 rgba($darkSideOfTheForce, 0.26);
    }

    &__scaleOnHover {
        &:hover {
            transform: scale(1.1);
        }

        .text {
            display: inline-block;
            padding-bottom: 1px;
        }

        &.button__disabled:hover {
            transform: scale(1);
        }

        &.button__styleType_invertedWhite:hover,
        &.button__styleType_invertedWhite:focus {
            background-color: $sophieWhite;
        }
    }

    &__rounded {
        border-radius: 20px;
    }
}
