$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/generated/assets/assets';

.easterDiscountHeader {
    position: relative;
}

.header {
    padding-top: $global-spacing-small;
    color: $sophieWhite;
    text-align: center;
}

.headline {
    font-size: 18px;
}

.subHeadline {
    font-size: 16px;
}

.countDownHeader {
    margin-top: $global-spacing-small;
    font-size: 14px;
}

.countdown {
    display: flex;
    justify-content: center;
    padding-bottom: $global-spacing-medium;
}

.lawn {
    position: absolute;
    bottom: 0;
    z-index: -1;
}
