$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/mixins';

.nearbySuggestionItem {
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;

    &:focus,
    &:active {
        background: $blueFire;

        .icon,
        .text {
            color: $alabaster;
        }
    }
}

.icon {
    display: flex;
    font-size: 32px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    color: $blueFire;
    width: 50px;
    height: 50px;
}

.text {
    display: inline-flex;
    align-self: center;
    vertical-align: middle;
    color: $blueFire;
    font-size: 16px;
    line-height: 1;
    margin-left: 10px;
    font-weight: bold;
}
