$forMobile: "true";@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

.roomConfigurationFields {
    display: flex;
    flex-flow: column;
    min-width: 290px;
    row-gap: $global-spacing-smedium;
}

.field {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title {
    font-size: 16px;
    line-height: 18px;
}

.subTitle {
    color: $dustyGrey;
    font-size: 14px;
}
