$forMobile: "true";@import 'Application/view/framework/common/settings';

.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 530px;
}

.headline {
    margin: $global-spacing 0;
    font-size: 20px;
    line-height: 1.2;
}

.image {
    margin-bottom: $global-spacing;
    width: 100%;
}

.content {
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: $global-spacing-small;
}

.hint {
    font-size: 12px;
    line-height: 1.2;
    margin-bottom: $global-spacing-small;
}
