$forMobile: "true";@import 'Application/view/framework/common/settings';

.wrapper {
    padding: $global-spacing 10px 0;

    &__withPaddingBottom {
        padding-bottom: $global-spacing;
    }
}

.headline {
    margin-bottom: $global-spacing;
    font-size: 20px;
}

.elements {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $global-spacing-xsmall;
}
