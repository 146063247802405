$forMobile: "true";@import 'Application/view/framework/common/settings';

.headline {
    font-size: 20px;
    padding: 0 10px;
    margin: $global-spacing 0;
}

.topicsSlider {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    padding-left: 10px;
}

.topics {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: $global-spacing;
}

.locations {
    height: 180px; // Same height as tiles plus a little padding
}
