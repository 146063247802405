$forMobile: "true";@import 'Application/view/generated/colors';
@import 'Application/view/generated/assets/assets';

.plectrum {
    display: flex;
    align-items: center;
    text-align: center;
    min-width: 88px;
    min-height: 70px;
    color: $sophieWhite;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url($plectrumSvg);
}

.text {
    line-height: 1;
    font-size: 12px;
    margin-left: 20px;
}

.discount {
    font-size: 20px;
    font-weight: bold;
}

.infoIcon {
    font-size: 10px;
    padding-left: 5px;
}
