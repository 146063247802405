$forMobile: "true";@import 'Application/view/framework/common/settings';

.topDestinationCategory {
    margin: $global-spacing 0;
}

.headline {
    font-size: 20px;
    padding: 0 10px;
    margin-bottom: $global-spacing;
    line-height: 1.4;
}

.links {
    padding: 0 10px;
}
