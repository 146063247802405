$forMobile: "true";@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

$digitHeightNormal: 28px;
$digitHeightBigger: 36px;
$digitWidth: 24px;
$flip-top-timing: cubic-bezier(0.37, 0.01, 0.94, 0.35);
$flip-bottom-timing: cubic-bezier(0.15, 0.45, 0.28, 1);

.flipClock {
    display: flex;
    text-align: center;
    gap: $global-spacing-small;

    &__normal {
        .card {
            font-size: $digitHeightNormal;
            height: $digitHeightNormal;
        }

        .cardTop,
        .cardBottom,
        .cardBack:before,
        .cardBack:after {
            height: calc($digitHeightNormal / 2);
        }

        .cardBottom:after {
            margin-top: -(calc($digitHeightNormal / 2) + 1);
        }
    }

    &__bigger {
        .card {
            font-size: 30px;
            height: $digitHeightBigger;
        }

        .cardTop,
        .cardBottom,
        .cardBack:before,
        .cardBack:after {
            height: calc($digitHeightBigger / 2);
            padding-top: 3px;
        }

        .cardBottom:after {
            margin-top: -(calc($digitHeightBigger / 2) + 1);
        }
    }
}

.timeUnit {
    display: flex;
    flex-direction: column;
    gap: $global-spacing-small;
}

.digits {
    display: flex;
    gap: 2px;
}

.card {
    display: flex;
    position: relative;
    overflow: hidden;
    line-height: 1;
    width: $digitWidth;
}

.cardTop,
.cardBottom,
.cardBack:before,
.cardBack:after {
    display: block;
    color: $silverStar;
    background: $sophieWhite;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    width: $digitWidth;
    transform: translateZ(0);
}

.cardBottom {
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: solid 1px $dustyGrey;
    pointer-events: none;
    overflow: hidden;

    &:after {
        display: block;
        content: attr(data-value);
    }
}

.cardBack {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    pointer-events: none;

    &:before {
        position: relative;
        z-index: -1;
        overflow: hidden;
        content: attr(data-value);
    }
}

.flip {
    .cardBack:before {
        animation: flipTop 0.3s $flip-top-timing both;
        transform-origin: center bottom;
    }

    .cardBack .cardBottom {
        transform-origin: center top;
        animation: flipBottom 0.6s $flip-bottom-timing both;
    }
}

.slot {
    font-size: 14px;
    color: $sophieWhite;
    text-transform: uppercase;
}

@keyframes flipTop {
    0% {
        transform: rotateX(0deg);
        z-index: 2;
        opacity: 0.99;
    }

    99% {
        opacity: 0.99;
    }

    100% {
        transform: rotateX(-90deg);
        opacity: 0;
    }
}

@keyframes flipBottom {
    0%,
    50% {
        z-index: -1;
        transform: rotateX(90deg);
        opacity: 0;
    }

    51% {
        opacity: 0.99;
    }

    100% {
        opacity: 0.99;
        transform: rotateX(0deg);
        z-index: 5;
    }
}
