$forMobile: "true";.svgIcon {
    display: inline-flex;
    vertical-align: baseline;
    font-size: inherit;
    justify-content: center;
    width: 1em;
    height: 1em;
    overflow: hidden;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;

    // fix for safari when the border of an icon was cut off
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);

    > svg {
        // this is a fix for safari
        height: 100%;
        width: 100%;
    }

    &__withoutWidth {
        width: unset;

        > svg {
            width: unset;
        }
    }

    &__withoutHeight {
        height: unset;
    }
}
