$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/generated/award-slider';

.accordionWrapper {
    &__withPadding {
        padding: $global-spacing 10px;
    }
}

.headline {
    font-size: 20px;
    margin-bottom: $global-spacing;
}

.accordion {
    padding: 0 8px;
    border: 1px solid $marktMaedels;
}

.item {
    padding: 8px;

    & + .item {
        border-top: 1px solid $marktMaedels;
    }
}

.iconAndTitle {
    display: flex;
    align-items: center;
}

.iconContainer {
    display: flex;
    justify-content: center;
    margin-right: 10px;
    flex: 0 0 45px;
    font-size: 36px;
    line-height: 1;
}

.bestPriceBadge {
    height: 44px;
}

.titleContainer {
    font-size: 16px;
    font-weight: bold;
    padding-right: $global-spacing;
}

.arrowIcon {
    $arrowSize: 25px;

    margin-left: auto;
    font-size: $arrowSize;
    line-height: 1;
    height: $arrowSize;
    color: $plainGrey;
}

.content {
    font-size: 14px;
    padding: $global-spacing-smedium 0 $global-spacing-xsmall $global-spacing-xsmall;
}
