$forMobile: "true";@import 'Application/view/framework/common/mixins/lightedGrayscale';

.supplierLogo {
    flex: 1;
    height: 15px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    &__filterLightGrey {
        @include lightedGrayscale;
    }

    &__filterGrey {
        filter: grayscale(100%);
    }

    &__sizeBig {
        height: 20px;
    }
}
