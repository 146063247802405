$forMobile: "true";@import 'Application/view/framework/common/settings';

.easterAttackBottomSheet {
    text-align: center;
}

.headline {
    font-size: 20px;
    font-weight: bold;
    padding: $global-spacing 0 $global-spacing-small;
}

.teaser {
    font-size: 16px;
    margin: $global-spacing-small 0;
}
