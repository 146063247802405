$forMobile: "true";@import 'Application/view/framework/common/settings';

.chatbotHeader {
    padding: 0 10px;
}

.chips {
    display: flex;
    white-space: nowrap;
    gap: $global-spacing-small;
    padding: 0 10px $global-spacing-smedium;
}

.searchTermInput {
    padding: 0 10px $global-spacing;
}
