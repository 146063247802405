$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.icons {
    background-color: $frosteon;
    border-radius: 50%;
    margin: 0 auto 10px;
    width: 55px;
    height: 55px;
    position: relative;
}

.emailIcon {
    position: absolute;
    top: 15px;
    left: 10px;
    font-size: 20px;

    // The svg is not a direct child.
    & svg {
        fill: $buckIsBluetiful;
    }
}

.phoneIcon {
    position: absolute;
    top: 20px;
    left: 24px;
    font-size: 24px;

    // The svg is not a direct child.
    & svg {
        stroke-width: 3px;
        color: $buckIsBluetiful;
    }
}
