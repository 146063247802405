$forMobile: "true";@import 'Application/view/generated/zIndices';

.promotionWrapper {
    position: absolute;
    max-width: inherit;
    top: 10px;
    left: 10px;

    /**
     * This component might be located above a slick slider
     * component whose arrow elements have a high z-index.
     * So we need to surpass that.
     */
    z-index: zIndex(partialElements) + 1;

    &__bottomRight {
        top: unset;
        left: unset;
        bottom: 10px;
        right: 10px;
    }

    &__noPositioning {
        position: static;
        max-width: inherit;
        top: 0;
        left: 0;
    }
}
