$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Icons/view/generated/icons';
@import 'Application/view/framework/common/mixins/selectNone';

$imageSizeDesktop: 40px;
$imageSizeMobile: 50px;
$destinationLineHeight: 19px;

.destinationSuggestion {
    display: flex;
    align-items: center;
    padding: 7px 0;
    cursor: pointer;

    &:hover {
        background: $almostWhite;
    }

    &:last-of-type {
        border-bottom: none;
    }

    &:first-of-type {
        margin-top: $global-spacing-small;
    }

    &__highlighted {
        background-color: $almostWhite;
    }

    &__mobile {
        padding: $global-spacing-small 0;
        border-bottom: 1px solid $almostWhite;

        &:first-of-type {
            margin-top: 0;
            padding-top: 0;
        }

        &:last-of-type {
            padding-bottom: 0;
        }

        .displayName {
            flex-grow: 1;
            line-height: 1.2;
        }

        .badgeContainer {
            justify-content: flex-end;
        }

        .details {
            justify-content: space-between;
            width: calc(100% - #{$imageSizeMobile});
        }

        .label {
            font-size: 16px;
            font-weight: bold;
        }
    }

    &__desktop,
    &__tablet {
        .displayName {
            white-space: nowrap;
        }
    }
}

.icon {
    display: flex;
    font-size: 30px;
    padding: $global-spacing-xsmall;
}

.details {
    display: flex;
    flex: 1;
    width: calc(100% - #{$imageSizeDesktop});
    overflow: auto;
}

.displayName {
    @include selectNone;

    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
    line-height: $destinationLineHeight;
}

.shortDescription {
    line-height: 17px;
}

.label {
    font-size: 16px;
    font-weight: bold;

    .query {
        font-weight: normal;
    }

    &__mobile {
        font-size: 14px;
    }
}

.badgeContainer {
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    height: $destinationLineHeight;
}

.badge {
    border-radius: $global-radius;
    color: $sophieWhite;
    line-height: 1;
    padding: 2px 5px;
    margin-left: 10px;
    font-size: 12px;
    white-space: nowrap;
    background: $janJanJanJanJanJanJellow;

    &__bestseller {
        background-color: $goldDrop;
    }
}
