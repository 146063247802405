$forMobile: "true";@import 'Application/view/framework/common/settings';

.plusMinusInput {
    display: flex;

    &__small {
        .value {
            font-size: 14px;
            font-weight: bold;
            width: 25px;
            height: 25px;
        }
    }
}

.value {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    width: 36px;
    height: 36px;
}
