$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.loginTeaserAnonymousMobile {
    padding: $global-spacing;
    position: relative;
    border-radius: 7px;
    box-shadow: 1px 1px 10px rgba($darkSideOfTheForce, 0.2);
    border: 1px solid $goldieMcGoldface;
    display: flex;
    flex-direction: column;
    row-gap: $global-spacing;
}

.goldClub {
    color: $goldieMcGoldface;
}

.textAndImage {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    column-gap: $global-spacing-small;
}

.teaser {
    color: $goldieMcGoldface;
}

.title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: $global-spacing-smedium;
}

.subTitle {
    font-size: 14px;
    line-height: 24px;
    flex: 1;
}

.buttons {
    display: flex;
    gap: $global-spacing-medium;
    justify-content: center;
}
