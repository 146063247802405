$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/css-components/searchformVariables';

$inputSpaces: 5px;
$inputFieldHeight: 52px;
$searchFormPadding: 16px;

.searchFormMobile {
    position: relative;

    &__alignBackgroundWithDestinationField {
        // Using negative margin to align the background if the signets are deactivated
        margin-top: -($inputSpaces + $inputFieldHeight + $searchFormPadding);
    }

    *[tabindex] {
        outline: none;
        -webkit-tap-highlight-color: transparent;
    }
}

.container {
    background-color: $janJanJanJanJanJanJellow;
    padding: $inputSpaces;
    border-radius: $searchform-border-radius;
}

.destination {
    margin-bottom: $inputSpaces;
    position: relative;
}

.dateRange {
    margin-bottom: $inputSpaces;
    position: relative;
}

.roomConfiguration {
    margin-bottom: $inputSpaces;
}
