$forMobile: "true";@import 'Application/view/framework/common/settings';

.wrapper {
    display: block;

    &__mobile {
        .title {
            font-size: 17px;
            line-height: 20px;
            margin-bottom: $global-spacing-smedium;
        }

        .text {
            font-size: 16px;
            line-height: 18px;
        }
    }
}

.title {
    font-size: 13px;
    line-height: 16px;
    font-weight: bold;
    margin-bottom: $global-spacing-small;
}

.text {
    font-size: 13px;
    line-height: 16px;
}
