$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.headline {
    font-size: var(--price-overview-font-size, 16px);
    line-height: 18px;
    font-weight: bold;
    margin-bottom: $global-spacing-small;
}

.benefitItems {
    display: flex;
    flex-direction: column;
    row-gap: $global-spacing-small;
}

.payoutHint {
    font-size: 12px;
    line-height: 14px;
    color: $doveGrey;
    margin-top: $global-spacing-small;
}
