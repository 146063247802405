$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.loyaltyTeaser {
    background-color: $sophieWhite;
    margin: $global-spacing 0;
}

.headline {
    margin: $global-spacing-smedium 10px;
    font-size: 20px;
    color: $silverStar;
}
