$forMobile: "true";@import 'Application/view/framework/common/settings';

.message {
    font-size: 11px;
    margin-top: $global-spacing-small;

    &__mobile {
        font-size: 14px;
        line-height: 16px;
        margin-top: $global-spacing-xsmall;
    }

    &__isExternal {
        font-size: 12px;
        margin-top: 0;
    }
}
