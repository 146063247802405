$forMobile: "true";@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

.background {
    background: $sophieWhite;
    padding: $global-spacing 10px;
    border-radius: $global-radius;
    display: flex;
    justify-content: space-between;
    gap: $global-spacing-smedium;

    &__asHotelTile {
        margin: 0 $global-spacing-small $global-spacing;
        box-shadow: 0 1px 8px rgba($darkSideOfTheForce, 0.1);

        .mobileHeadline {
            font-size: 16px;
        }
    }

    &__resultsRedesign {
        padding: $global-spacing $global-spacing-small;
        margin: 0;
        box-shadow: unset;
    }
}

.mobileHeadline {
    font-size: 18px;
    line-height: 1;
    font-weight: bold;
    margin: 0 0 10px;
}

.content {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: $global-spacing-smedium;
}
