$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/quadrat';

.lastSearchesTile {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    color: $silverStar;
    border: 1px solid $marktMaedels;
    border-radius: 5px;
    cursor: pointer;
    column-gap: $global-spacing-smedium;

    // After redirection to link the component stays focused and is styled in blue font
    &:focus {
        color: $silverStar;
    }

    &__touchDevice {
        &:hover {
            color: $silverStar;
        }
    }

    &__mouseDevice {
        &:hover {
            box-shadow: 0 0 5px 1px rgba($darkSideOfTheForce, 0.14);
            color: $silverStar;

            .displayName {
                color: $juna;
            }
        }
    }

    &__mobile {
        cursor: default;
        column-gap: $global-spacing-smedium;

        &:hover {
            color: $silverStar;
        }

        .imageContainer {
            padding: $global-spacing-smedium 0 $global-spacing-smedium $global-spacing-smedium;
        }

        .image {
            @include quadrat(64px);
        }

        .contentContainer {
            padding: $global-spacing-smedium $global-spacing-smedium $global-spacing-smedium 0;
            line-height: 1.3;
        }

        .travelDataContainer {
            display: block;
        }
    }
}

.imageContainer {
    padding: $global-spacing 0 $global-spacing $global-spacing-smedium;
    align-self: flex-start;
}

.image {
    border-radius: 4px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    transition: transform 250ms ease-in-out;

    @include quadrat(83px);

    &__withFallbackImage {
        $blur: 5px;

        filter: blur($blur);
        margin: -$blur;

        @include quadrat(calc(100% + #{$blur * 2}));
    }
}

.contentContainer {
    flex: 1;
    align-self: flex-start;
    padding: $global-spacing-smedium $global-spacing $global-spacing-smedium 0;
    overflow: hidden;
}

.displayName {
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.travelDataContainer {
    display: flex;
    flex-direction: column;
}

.filterAndPriorityBoostContainer {
    display: flex;
    flex-direction: column;
    margin-top: $global-spacing-xsmall;
    gap: $global-spacing-xsmall;
    line-height: normal;
}

.iconTextContainer {
    display: flex;
    align-items: center;
    gap: $global-spacing-small;
}

.filterIcon {
    font-size: 15px;
    align-self: flex-start;
    display: flex;
    align-items: center;
}

.priorityBoostIcon {
    margin: 1.5px;
    align-self: flex-start;
    display: flex;
    align-items: center;
}

.closeIcon {
    position: absolute;
    display: flex;
    top: 12px;
    right: 12px;
    font-size: 14px;
    color: $plainGrey;
}
