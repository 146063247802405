@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Icons/view/generated/icons';
@import 'Application/view/framework/common/mixins/mixins';

@mixin sideIconBox($icon) {
    &:before {
        @include iconFont;

        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 32px;
        line-height: 24px;
        color: $grey;
        content: $icon;
    }
}

$componentClass: frontpageBox;

.#{$componentClass} {
    position: relative;
    border: 1px solid $marktMaedels;
    border-radius: 3px;
    font-size: 14px;

    // for <a>'s
    display: block;

    &,
    &:hover {
        color: inherit;
    }

    &--gutter {
        padding: 10px 10px 0;
    }

    &--withMarginBottom {
        margin-bottom: $global-spacing;
    }

    &--withLargeMarginBottom {
        margin-bottom: $global-spacing-large;
    }

    &--linkArrow {
        padding-right: 45px;

        @include sideIconBox($arrowContourRightIcon);
    }

    &__ekomiMobileHeadline,
    &__awardWinnerMobileHeadline {
        font-size: 20px;
        margin-bottom: $global-spacing;
    }

    &__advantagesAccordion {
        margin-bottom: $global-spacing;
    }
}
