$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.container {
    display: flex;
    flex-direction: column;
    row-gap: $global-spacing-small;
}

.effectivePriceContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    font-weight: bold;
}

.headline {
    font-size: var(--price-overview-font-size, 16px);
    line-height: 18px;
    font-weight: normal;

    &__withBoldLabel {
        font-weight: bold;
    }
}

.price {
    font-size: var(--price-overview-font-size, 16px);
    line-height: 18px;

    &__withBigPrice {
        font-size: 20px;
        line-height: 22px;
    }
}

.hint {
    font-size: 12px;
    line-height: 14px;
    color: $doveGrey;
}
