$forMobile: "true";@import 'Application/view/framework/common/settings';

.overlayWrapper {
    &__fullWidth {
        width: 100%;
        padding: $global-spacing;
    }
}

.overlay {
    // Necessary for closing icon
    position: relative;
}
