$forMobile: "true";@import 'Application/view/framework/common/settings';

.bestPricePromiseEyeCatcherContents {
    display: flex;
}

.bestPriceLogo {
    flex: 0 0 68px;
    margin-right: $global-spacing;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.promise {
    font-size: 14px;
}
