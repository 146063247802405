$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.container {
    display: flex;
    justify-content: space-between;
    gap: $global-spacing-small;
}

.inputContainer {
    flex: 1;
}

.textAreaAndButton {
    display: flex;
    gap: $global-spacing-small;
}

.input {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
}

.iconContainer {
    position: absolute;
    top: 6px;
    left: 6px;
}

.icon {
    height: 22px;
    width: 22px;
}

.textArea {
    resize: none;
    width: 100%;
    min-height: 34px;
    max-height: 60px;
    margin: 0;
    color: $silverStar;
    border-radius: 25px;
    border-color: $marktMaedels;
    padding: $global-spacing-small $global-spacing;
    font-size: 14px;
    overflow: hidden;

    // Set initial height to be able to position icon correctly
    height: 34px;

    // Make element scrollable but hide scrollbar.
    &__showScrollElement {
        overflow: visible;
        -ms-overflow-style: none; /* For IE and Edge */
        scrollbar-width: none; /* For Firefox */

        &::-webkit-scrollbar {
            display: none; /* For Chrome, Safari, Opera */
        }
    }

    &__showIcon {
        padding-left: 35px;
    }
}

.limitWarning {
    font-size: 11px;
    top: 100%;
    text-align: right;
    color: $doveGrey;
    margin-right: $global-spacing-medium;
}

.sendIcon {
    background: $blueStone;
    flex: 0 0 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    border: 0;
    border-radius: 50%;
    outline: 0;
    align-self: end;

    &__withBottomMargin {
        margin-bottom: $global-spacing;
    }
}
