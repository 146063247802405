$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/generated/zIndices';
@import 'Application/view/framework/common/mixins/mixins';

.mobileRoomConfigurationOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: zIndex(wireframeElements);
    background: rgba($darkSideOfTheForce, 0.5);
}

.contents {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: auto;
}

.header {
    height: 50px;
    padding: $global-spacing;
    display: flex;
    align-items: center;
    background-color: $buckIsBluetiful;
    color: $sophieWhite;
}

.headerCloseIcon {
    margin-right: 20px;
    font-size: 28px;
    line-height: 18px;
    position: absolute;
}

.headerLabel {
    font-size: 18px;
    font-weight: bold;
    flex-grow: 1;
    text-align: center;
}

.roomConfiguration {
    flex: 1;
    overflow: hidden auto;
    background: $sophieWhite;
}

.acceptButtonContainer {
    padding: 20px 10px 10px;
    background-color: $sophieWhite;
}
