@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/mixins';

$componentClass: contact;
$componentFontSize: 14px;

.#{$componentClass} {
    display: block;
    position: relative;
    padding: 15px 10px;

    .#{$componentClass}__text {
        margin-bottom: 0;
        color: $dustyGrey;
        font-size: $componentFontSize;
        line-height: 16px;
    }

    &__row {
        @include iconPhoneBefore;

        &:before {
            color: $blueFire;
            transform: translate(0, -50%);
            top: 50%;
        }

        &--noIcon:before {
            content: unset !important;
        }

        &--topPadding {
            padding-top: ($global-line-height * 0.5) * $componentFontSize;
        }
    }
}
