$forMobile: "true";@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';
@import 'Application/view/components/splashScreen/common/variables';

.bonusBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: $bonusBoxWidth;
    height: 95px;
    color: $sophieWhite;
    background-color: $vermilion;
    border-radius: $global-radius;

    &__mobile {
        width: $bonusBoxWidthMobile;
        height: 80px;

        .amount {
            font-size: 45px;
        }
    }
}

.amountContainer {
    display: flex;
    line-height: 1;
}

.amount {
    position: relative;
    font-size: 46px;
    font-weight: bold;
}

.currency {
    display: flex;
    flex-direction: column;
    font-size: 22px;
    font-weight: bold;

    > span {
        display: block;
        margin-top: 5px;
    }

    span:nth-of-type(2) {
        margin-top: -10px;
        font-size: 24px;
    }
}

.text {
    font-size: 22px;
    font-weight: bold;
}
