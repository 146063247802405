$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.popularDestinationsMobile {
    margin: $global-spacing 0;
}

.headline {
    font-size: 20px;
    padding-left: 0.625 * $global-spacing;
    margin-bottom: $global-spacing;
}

.content {
    position: relative;
    overflow-x: auto;
}

.figure {
    position: relative;
    display: flex;
    border-radius: $global-radius;
    overflow: hidden;
    min-height: 200px;
    object-fit: cover;
    z-index: -1;

    // Override figure-margin to ensure correct size for critical-css
    margin-inline: 0;

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 50%;
        background: linear-gradient(to top, rgba($darkSideOfTheForce, 0.5), rgba($darkSideOfTheForce, 0));
    }
}

.caption {
    position: absolute;
    bottom: 0;
    color: $sophieWhite;
    text-align: center;
    margin: (2 * $global-spacing) 0;
    width: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    word-break: break-word;
}

.title {
    display: block;
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: $global-spacing;
}

.price {
    font-size: 16px;
}
