$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.list {
    display: flex;
    cursor: pointer;

    &__isListFull {
        cursor: not-allowed;
    }
}

.imageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.image {
    width: 60px;
    height: 60px;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
}

.emptyListImage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    font-size: 40px;
    color: $blueFire;
}

.information {
    padding: 0 $global-spacing-small;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.listName {
    font-size: 16px;
    font-weight: bold;
}

.listEntries {
    font-size: 14px;
    color: $doveGrey;

    &__warning {
        color: $ersterFussballClubKoeln;
    }
}
