$forMobile: "true";@import 'Application/view/generated/assets/assets';

.check24-app-icon {
    display: inline-block;
    width: 64px;
    height: 64px;
    background-image: url($check24AppIconPng); // TODO add to c24 sprite
    border-radius: 10%;
    background-size: contain;

    .webp & {
        background-image: url($check24AppIconWebp);
    }

    &--small {
        width: 44px;
        height: 44px;
        background-size: 44px;
    }
}
