$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/css-components/overlayVariables';

.closeIcon {
    position: absolute;
    bottom: calc(100% + 10px);
    right: 0;
    font-size: 25px;
    line-height: 1;
    color: $sophieWhite;
    cursor: pointer;
    transition: 0.25s color;

    &__mobile {
        font-size: 20px;
    }

    &:hover {
        color: $marktMaedels;
    }

    &__withGapOnRightSide {
        right: $overlayCloseIconGap;
    }
}
