$forMobile: "true";@import 'Application/view/framework/common/settings';

.mobileFrontPageHeader {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;

    &__es {
        margin-bottom: $global-spacing-xsmall;
    }
}

.titleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 10px;
}

.title {
    font-size: 20px;
    margin-bottom: 0;
}
