$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.tile {
    height: 160px;
    overflow: hidden;
}

.locationImage {
    border-radius: 5px;
}

.content {
    padding-top: $global-spacing-smedium;
}

.name {
    font-size: 16px;
}

.count {
    font-size: 14px;
    color: $dustyGrey;
}
