$forMobile: "true";@import 'Application/view/generated/colors';

.suppliers {
    display: flex;
    flex-direction: column;
}

.supplierLogos {
    margin: 5px 0 3px;
    display: flex;
}

.suppliersHint {
    font-size: 11px;
    align-self: flex-end;
}
