$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.container {
    &__mobile {
        border: 1px solid $marktMaedels;
        padding: $global-spacing;
        border-radius: 5px;
    }
}

.radio {
    margin-bottom: $global-spacing;

    &:last-child {
        margin-bottom: 0;
    }
}
