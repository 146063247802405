$forMobile: "true";@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

.wrapper {
    background: $almostWhite;
    padding-top: 10px;
}

.container {
    padding: $global-spacing 10px;
    background: $sophieWhite;
}
