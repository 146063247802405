$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.hotelRating {
    flex-wrap: nowrap;
    display: flex;
    align-items: center;

    &__orientationRightToLeft {
        text-align: right;
    }

    &__justifyLeft {
        justify-content: flex-start;
    }

    &__justifyCenter {
        justify-content: center;
    }

    &__justifyRight {
        justify-content: flex-end;
    }
}

.detailsColumn {
    display: flex;
}

.details {
    display: flex;
    white-space: nowrap;
    justify-content: space-between;
    line-height: 1;
    color: $silverStar;

    &__desktop {
        flex-direction: column;
    }

    &__mobile {
        align-items: center;

        &.details__twoLines {
            align-items: flex-start;
            flex-direction: column;

            .adjective__smaller {
                line-height: 1;
            }
        }
    }

    &__huge {
        height: 48px;
        justify-content: center;

        .adjective {
            font-size: 19px;

            &__disabled {
                font-size: 14px;
            }
        }

        .count {
            font-size: 14px;
        }
    }

    &__justifyCenter {
        justify-content: center;
    }

    &__singleLine {
        flex-direction: row;
        align-items: center;
        gap: $global-spacing-xsmall;

        .adjective {
            margin-bottom: unset;
            line-height: 1;
        }

        .count {
            padding: unset;
        }
    }

    &__normalFontWeight {
        .adjective {
            font-weight: normal;
        }

        .count {
            font-weight: normal;
        }
    }
}

.adjective {
    font-size: 14px;
    line-height: 1.3;
    font-weight: bold;
    color: $silverStar;

    &__smaller {
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 2px;

        &.adjective {
            &__wrap {
                max-width: 90px;
            }
        }
    }

    &__disabled {
        font-size: $global-font-size;
        font-weight: normal;
    }

    &__wrap {
        white-space: normal;
        max-width: 122px;
    }
}

.count {
    font-size: 12px;
    line-height: 1;
    color: $silverStar;
    padding-top: 2px;

    &__disabled {
        font-size: $global-font-size;
        font-weight: normal;
    }

    &__huge {
        font-size: 14px;
    }
}

.singleLineDot {
    color: $grey;
}
