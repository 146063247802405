$forMobile: "true";@import 'Application/view/framework/common/mixins/safeAreaInset';

@keyframes slidingOverlaySlideIn {
    0% {
        transform: translate3d(0, var(--dynamic-viewport-height), 0);
    }

    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slidingOverlaySlideOut {
    0% {
        transform: translate3d(0, 0, 0);
    }

    100% {
        transform: translate3d(0, var(--dynamic-viewport-height), 0);
    }
}

.slidingOverlayContainerMobile {
    @media (orientation: portrait) {
        @include safeAreaInset('padding-top', 'padding-bottom', 'padding-left', 'padding-right');
    }

    display: flex;
    height: var(--dynamic-viewport-height);
    max-height: 100%;
    flex-direction: column;
    will-change: transform;
    transform: translate3d(0, var(--dynamic-viewport-height), 0);

    // fixes weird position bug on ios chrome
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    &__isSlidIn,
    &__isSlidOut {
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
        animation-direction: normal;
    }

    &__isSlidIn {
        animation-name: slidingOverlaySlideIn;
    }

    &__isSlidOut {
        animation-name: slidingOverlaySlideOut;
    }
}

.overlayViewport {
    overflow: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: var(--dynamic-viewport-height);
    will-change: transform;
}
