$forMobile: "true";@import 'Application/view/generated/colors';

.placeholderFallbackWrapper {
    display: block;
    transition: all 600ms ease-out;

    &__hidden {
        height: 0;
    }
}
