$forMobile: "true";@import 'Application/view/search-form/components/mixins/inputOnYellowMask';
@import 'Application/view/framework/common/destination-suggestion/mixins/heightAdjustedIcon';
@import 'Application/view/framework/common/settings';
@import 'Icons/view/generated/icons';
@import 'Application/view/generated/colors';
@import 'Application/view/css-components/searchformVariables';

$leftPadding: 10px;

.destinationSuggestionQueryInput {
    position: relative;
    height: 59px;

    &__hasInvalidValue {
        .searchIcon,
        .clearIcon,
        .searchIconBefore {
            color: get-color(alert);
        }

        .input {
            border-color: get-color(alert);
        }
    }

    &__results {
        height: auto;

        .label {
            left: 9px;
            color: $silverStar;
        }

        .clearIcon {
            @include heightAdjustedIcon($input-height);

            padding: 6px 8px;
        }

        &.destinationSuggestionQueryInput {
            &__highlightInput {
                .input {
                    // padding needs to be changed when the input has a 2px border, for !important see first "&__input" selector at the top
                    padding-left: 7px !important;
                }
            }
        }
    }

    &__offers {
        height: auto;

        .label {
            left: 9px;
            color: $silverStar;
        }

        .clearIcon {
            @include heightAdjustedIcon($input-height);

            padding: 6px 8px;
        }
    }

    &__singleLine {
        &.destinationSuggestionQueryInput {
            &__tablet {
                &.destinationSuggestionQueryInput {
                    &__isExternal {
                        .input {
                            font-size: 16px !important;
                        }
                    }
                }

                .input {
                    font-size: 16px !important;
                }
            }
        }
    }

    &__mobile {
        $inputHeight: 52px;

        height: $inputHeight;

        .input {
            // for !important see first ".input" selector at the top
            padding: 10px 34px 10px 15px !important;
            font-size: $searchform-input-font-size !important;
            color: $silverStar;
            border: none;

            // some devices style inputs with type="search" to have a default background color (e.g. iOS 15)
            background-color: $sophieWhite;

            &::placeholder {
                color: $dustyGrey;
                font-weight: normal;
            }

            // clears the 'X' from Chrome
            &::-webkit-search-decoration,
            &::-webkit-search-cancel-button,
            &::-webkit-search-results-button,
            &::-webkit-search-results-decoration {
                display: none;
            }

            &:disabled {
                background-color: $sophieWhite;

                &::placeholder {
                    color: $dustyGrey;
                }
            }
        }

        .searchIcon,
        .clearIcon {
            font-size: 30px;
        }

        .clearIcon {
            @include heightAdjustedIcon($inputHeight);
        }
    }

    &__mobile.destinationSuggestionQueryInput__hasInvalidValue {
        .input {
            color: $ersterFussballClubKoeln;

            &::placeholder {
                color: $ersterFussballClubKoeln;
            }
        }
    }

    &__mobile.destinationSuggestionQueryInput__withLabel {
        .input {
            padding: 26px 32px 10px $leftPadding !important;
        }

        .label {
            color: $doveGrey;
            font-size: 13px;
        }
    }

    &__mobile.destinationSuggestionQueryInput__withLeadingSearchIcon {
        display: flex;
        align-items: center;
        column-gap: $global-spacing-small;

        .input {
            padding-left: 0 !important;
        }

        &.destinationSuggestionQueryInput__withLabel {
            .searchIconBefore {
                margin-top: 22px;
            }
        }
    }

    &__mobile.destinationSuggestionQueryInput__withBoldInputFontOnMobile {
        .input {
            font-weight: bold;
        }
    }

    &__highlightInput {
        .input {
            border: 2px solid $blueFire;
            border-radius: $searchform-border-radius $searchform-border-radius 0 0;

            // padding needs to be changed when the input has a 2px border
            padding-left: ($leftPadding - 1px) !important;
        }

        &__mobile {
            .input {
                // padding needs to be changed when the input has a 2px border
                padding: 8px 32px 8px 14px !important;
            }
        }
    }

    &__touchDevice {
        .input {
            &:hover {
                border-color: transparent;
            }
        }
    }

    &__isC9SearchForm {
        .label {
            color: $silverStar;
        }
    }

    &__isExternal {
        .label {
            top: 7px;
            font-size: 12px;
        }

        .input {
            font-size: 16px;
            color: $silverStar;
        }
    }
}

.input {
    @include inputOnYellowMask;

    margin-bottom: 0;
    width: 100%;
    padding: 25px 35px 5px $leftPadding;
    font-size: 16px;
    text-overflow: ellipsis;
    cursor: pointer;
    color: $silverStar;

    &:focus-visible {
        outline: none;
    }

    // This hack is needed to overwrite the styling from foundations _print.scss between critical-css and normal stylings.
    &.input {
        height: 100%;
    }

    &::placeholder {
        color: $dustyGrey;
    }
}

.container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: auto;
}

.label {
    position: absolute;
    top: 6px;
    left: $leftPadding;
    font-size: 12px;
    line-height: 16px;
    pointer-events: none;
    width: calc(100% - 2 * #{$leftPadding});

    &__withoutTop {
        top: 0;
    }
}

.clearIcon {
    position: absolute;
    bottom: 5px;
    right: 0;
    padding: 6px 8px;
    font-size: 20px;
    line-height: 1;
    color: $plainGrey;
    cursor: pointer;

    &:hover + .destinationSuggestionQueryInput__input {
        border-color: $blueFire;
    }
}

.searchIcon {
    pointer-events: none;
    position: absolute;
    bottom: 5px;
    right: 0;
    padding: 6px 8px;
    font-size: 20px;
    line-height: 1;
    color: $plainGrey;
}

.searchIconBefore {
    margin: $global-spacing-xsmall 0 0 $global-spacing-small;
}
