$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/zIndices';
@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/c24Sprite';
@import 'Application/view/framework/common/mixins/quadrat';
@import 'Application/view/generated/assets/assets';

:global {
    // To let sticky work the element must be placed in a container which covers the whole page height.
    #appBannerContainer {
        position: sticky;
        top: 0;
        z-index: zIndex(stickyElements);
    }
}

.appBanner {
    display: flex;
    column-gap: $global-spacing;
    align-items: center;
    padding: 10px;
    background-color: $almostWhite;
}

.logo {
    border-radius: 3px;

    @include quadrat(48px);
}

.logoBox {
    flex: 0 0 auto;
}

.close {
    color: $plainGrey;
}

.text {
    font-size: 14px;
    line-height: 1.2;
    flex: 1 1 auto;

    &,
    &:hover {
        color: $silverStar;
    }
}

.link {
    font-size: 14px;
}

$starsWidth: 140px;
$starsHeight: 22px;
$scaleRatio: 0.5;
$starsValueOffset: 5px;

.rating {
    white-space: nowrap;
}

.starsContainer {
    width: $starsWidth * $scaleRatio;
    height: $starsHeight * $scaleRatio;
    display: inline-block;
    vertical-align: text-bottom;
}

.starsScaleContainer {
    transform: scale($scaleRatio);
    transform-origin: left top;
    width: $starsWidth;
    height: $starsHeight;
    display: block;
}

.stars {
    @include c24Sprite('star');

    display: block;
}

.ratingValue {
    margin-right: $starsValueOffset;
    height: $starsHeight * $scaleRatio;
    line-height: $starsHeight * $scaleRatio;
    display: inline-block;
    vertical-align: text-bottom;
}
