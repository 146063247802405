$forMobile: "true";@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

.headline {
    font-size: 20px;
    margin-bottom: $global-spacing;
}

.container {
    &,
    &:hover {
        color: inherit;
    }
}

.list {
    border: 1px solid $marktMaedels;
    border-radius: 3px;
    font-size: 14px;
    padding: $global-spacing-smedium 45px $global-spacing $global-spacing-smedium;
    position: relative;
    list-style: none;
    margin: 0;
}

.listItem {
    padding-left: 25px;
    font-size: 16px;
    line-height: 20px;
    position: relative;
}

.listItemCheck {
    position: absolute;
    left: 0;
    top: 3px;
    color: $blueFire;
    font-size: 10px;
}

.arrow {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 32px;
    line-height: 24px;
    color: $grey;
}
