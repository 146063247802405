@import 'Application/view/generated/colors';

$stateBoxPadding: 10px;

@mixin stateBox() {
    margin-bottom: 20px;
    border: 1px solid;
    border-radius: 5px;
    box-shadow: rgba($darkSideOfTheForce, 0.1) 0 1px 3px 0;
    background: $sophieWhite;
}

@mixin stateBoxHeader() {
    padding: $stateBoxPadding $stateBoxPadding ($stateBoxPadding - 1);
    font-weight: bold;
    border-bottom: 1px solid;
}
