$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.mobileFrontPage {
    margin-bottom: $global-spacing;

    &__isEasterDiscountCampaignActive {
        position: relative;
        margin: 0 -10px $global-spacing;
        padding: 0 10px;

        &:before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            height: 200px;
            background: radial-gradient(
                300px circle at center 50px,
                lighten($buckIsBluetiful, 15%) 0%,
                $buckIsBluetiful 60%,
                darken($buckIsBluetiful, 0%) 100%
            );
            z-index: -1;
        }

        &:after {
            position: absolute;
            content: '';
            top: 200px;
            left: 0;
            right: 0;
            height: 140px;
            background: linear-gradient(to bottom, $buckIsBluetiful 0%, transparent 100%);
            z-index: -1;
        }
    }
}

.productSwitch {
    margin-bottom: 10px;
}
