$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Icons/view/generated/icons';
@import 'Application/view/generated/colors';

.container {
    display: inline-flex;
}

.content {
    border-radius: $global-radius;
    font-size: 16px;
    line-height: 1.3;
    background-color: $sophieWhite;
    color: $silverStar;
    word-break: break-word;
}

.title {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0;
}
