$forMobile: "true";@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';
@import 'Application/view/framework/common/mixins/mixins';

.newsBannerHint {
    background-color: $sophieWhite;
    padding: 10px;
    font-size: 14px;
    line-height: 19px;
    position: relative;
}

.headline {
    font-size: 16px;
    margin-right: 26px;
}

.text {
    color: $silverStar;
    margin: $global-spacing-small 0;

    p {
        margin-bottom: $global-spacing-small;
        line-height: inherit;
    }
}

.toggle {
    position: absolute;
    top: -5px;
    right: 0;
    color: $plainGrey;
    font-size: 30px;
    line-height: 10px;
    padding: 10px;
    user-select: none;
}
