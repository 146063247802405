$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.mobileSectionSeparator {
    height: $global-spacing-small;
    background: $almostWhite;
}

.thinLine {
    border-bottom: 1px solid $marktMaedels;
    margin: $global-spacing-smedium 0;

    &__noMargin {
        margin: 0;
    }
}
