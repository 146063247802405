$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/generated/zIndices';

// importing non-react-library code directly, see below
@import 'perfect-scrollbar/css/perfect-scrollbar';

// This copy/paste code is all css from react-perfect-scrollbar's style.
// Sadly, importing the perfect-scrollbar's stylesheet from react-perfect-scrollbar's
// style won't work so can't use it.
.scrollbar-container {
    position: relative;
    height: 100%;
}

// With no z-index the PerfectScrollbar is always under elements that are positioned relative or absolute.
.ps__rail-x,
.ps__rail-y {
    z-index: zIndex(partialElements);
}

.perfectScrollbar--native {
    overflow: auto;

    &.perfectScrollbar--suppressScrollY {
        overflow-y: hidden;
    }

    &.perfectScrollbar--suppressScrollX {
        overflow-x: hidden;
    }
}

// Just a half solution, while scrolling above an iframe.
// While scrolling is active, we disable all pointer-events of the iframe,
// so the scrolling won't be interrupted.
.ps--scrolling-y iframe {
    pointer-events: none !important;
}
