$forMobile: "true";@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';
@import 'Application/view/framework/common/mixins/frontPage_mixins';
@import 'Application/view/generated/award-slider';

.title {
    @include frontPageHeadline;

    margin-bottom: $frontpage-headlines-margin-bottom;

    &__mobile {
        font-size: 20px;
        padding: 0;
        text-align: left;
        margin-bottom: $global-spacing;
        font-weight: normal;
    }

    &__lessMargin {
        margin-top: $global-spacing;
        margin-bottom: $startpage-attack-headlines-margin-bottom;
    }
}

.testWinner {
    display: flex;
    column-gap: $global-spacing-xlarge;
    justify-content: center;

    &__mobile {
        flex-flow: column;
        flex-direction: column-reverse;
        padding: $global-spacing-small;
        border: 1px solid $marktMaedels;

        :global {
            .dtgv2023 {
                margin-left: -18px;
            }
        }

        .column {
            flex: 0 0 auto;

            &:first-child {
                border-top: 1px solid $marktMaedels;
                padding-top: $global-spacing;
            }
        }

        .testWinnerBadges {
            justify-content: space-between;
        }

        .testWinnerLink {
            font-size: 16px;
        }
    }

    &__withBottomMargin {
        margin-bottom: $global-spacing-large;
    }
}

.testWinnerBadges {
    display: flex;
    justify-content: flex-end;

    :global {
        @include award-slider-retina-sprites($award-slider-retina-groups);

        @each $sprite in $award-slider-sprites {
            $sprite-name: nth($sprite, 10);
            $sprite-height: strip-unit(nth($sprite, 6));
            $container-height: 100;

            .#{$sprite-name} {
                transform: scale(calc(#{$container-height} / #{$sprite-height}));
            }
        }

        .focus2024 {
            margin-right: -24px;
        }
    }
}

.image {
    display: block;
    margin: auto;
    transform-origin: top;
    background-repeat: no-repeat;
}

.column {
    flex: 0 0 300px;
}

.testWinnerLink {
    text-align: right;
}
