$forMobile: "true";@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

@mixin publicProperty {
    font-weight: bold;
}

@mixin computedProperty {
    text-decoration: underline dotted;
}

@keyframes update {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.stateProperty {
    animation: update 0.75s ease-out 1;

    &__public {
        @include publicProperty;
    }

    &__computed {
        @include computedProperty;
    }
}

.values {
    // To see the property and not only the scrollbar while scrolling.
    padding-bottom: $global-spacing;
}
