$forMobile: "true";@import 'Application/view/generated/colors';
@import 'Application/view/css-components/searchformVariables';
@import 'Application/view/framework/common/mixins/selectNone';

$borderSize: 2px;

.dates {
    display: flex;
    position: relative;
    background-color: $sophieWhite;
    height: $searchform-input-height;
    font-size: $searchform-input-font-size;
    padding: ($borderSize * 2) $searchform-input-spacing-horizontal $borderSize;

    &__highlighted {
        padding: ($borderSize * 2) ($searchform-input-spacing-horizontal - $borderSize) $borderSize;
        border: $borderSize solid $blueFire;
    }

    &__invalid {
        .icon {
            color: $ersterFussballClubKoeln;
        }

        .content {
            color: $ersterFussballClubKoeln;

            &__withPlaceHolder {
                color: $ersterFussballClubKoeln;
            }
        }
    }
}

.content {
    display: flex;
}

.icon {
    width: 20px;
    text-align: center;
    margin: auto 6px auto 0;
    padding-top: 3px;
}

.dateRange {
    flex: 1;
    margin: auto 0;

    @include selectNone;

    &__withPlaceHolder {
        color: $dustyGrey;
        font-weight: normal;
    }

    &__withBoldInputFontOnMobile {
        font-weight: bold;
    }
}
