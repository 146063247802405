$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.loginTeaserAdvantageMobile {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.icon {
    flex: 0 0 auto;
    font-size: 16px;
    line-height: 1;
    margin-right: $global-spacing-small;
    color: $sophieWhite;
}

.text {
    flex: 1 1 auto;
    line-height: 24px;
    color: $sophieWhite;
}
