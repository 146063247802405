$forMobile: "true";@import 'Application/view/framework/common/mixins/safeAreaInset';
@import 'Application/view/framework/common/settings';

.badgeContainer {
    display: inline-flex;
}

.contentWrapper {
    &__app {
        @include safeAreaInset(padding-bottom);
    }
}

.content {
    padding: $global-spacing;
}

.title {
    font-size: 18px;
    line-height: 1;
    font-weight: bold;
    margin-bottom: $global-spacing;
}

.text {
    font-size: 16px;
    line-height: 20px;
}
