$forMobile: "true";@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

.form {
    display: flex;
    flex-flow: column;

    &__renderInRow {
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        column-gap: $global-spacing;

        .phoneNumberInput {
            margin-bottom: 0;
        }
    }

    &:not(&__onWhiteBackground) {
        .phoneNumberInput:focus {
            border: none;
        }
    }
}

.phoneNumberInput {
    margin-bottom: $global-spacing;
    padding: $global-spacing-small $global-spacing;
    border: 1px solid $marktMaedels;
    border-radius: $global-radius;
    font-size: 14px;
}
