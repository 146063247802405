$forMobile: "true";@import 'Application/view/framework/common/settings';

.remainingListCountReachedContainer {
    flex-direction: column;
    font-size: 14px;
    display: flex;
    max-width: 370px;
    row-gap: $global-spacing-small;
}

.toUserFavoritesButton {
    align-self: flex-end;
}
