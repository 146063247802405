$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/framework/common/mixins/mixins';
@import 'Application/view/generated/colors';

.teaser {
    width: 100%;
    background: transparent linear-gradient(180deg, $buckIsBluetiful, $darkSideOfTheForce);
    color: $sophieWhite;
    padding: $global-spacing 10px;
    border-radius: 7px;
}

.headline {
    font-size: 20px;
    margin-bottom: $global-spacing-medium;
}

.row {
    display: flex;
    font-size: 18px;

    + .row {
        margin-top: $global-spacing;
    }
}

.icon {
    font-size: 25px;
    line-height: 1;
    margin-right: $global-spacing;
    color: $janJanJanJanJanJanJellow;
}
