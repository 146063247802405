$forMobile: "true";@import 'Application/view/generated/colors';
@import 'Application/view/css-components/searchformVariables';
@import 'Application/view/framework/common/mixins/mixins';

$borderSize: 2px;

.roomConfiguration {
    display: flex;
    position: relative;
    background-color: $sophieWhite;
    height: $searchform-input-height;
    font-size: $searchform-input-font-size;
    border-radius: 0 0 $searchform-border-radius $searchform-border-radius;
    padding: ($borderSize * 2) $searchform-input-spacing-horizontal $borderSize;

    &__highlighted {
        padding: ($borderSize * 2) ($searchform-input-spacing-horizontal - $borderSize) $borderSize;
        border: $borderSize solid $blueFire;
    }
}

.content {
    display: flex;
    flex: 1;
}

.icon {
    width: 20px;
    text-align: center;
    padding-top: 3px;
    margin: auto 6px auto 0;
}

.text {
    flex-grow: 1;
    margin: auto 0;

    &__withBoldInputFontOnMobile {
        font-weight: bold;
    }
}
