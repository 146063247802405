$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/mixins';

.listCreateContainer {
    margin-top: $global-spacing;
}

.spinnerContainer {
    display: flex;
    flex: 0 0 90px;
    justify-content: center;
}

.errorMessage {
    margin-top: $global-spacing;
    color: $ersterFussballClubKoeln;
}
