$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.overlay {
    border-radius: 10px;
    background-color: $sophieWhite;
    position: relative;
    margin: 0 $global-spacing;
    padding: $global-spacing;
    font-size: 16px;
    color: $silverStar;
}
