$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/textOverflowEllipsis';
@import 'Application/view/framework/common/form/floating-labeled-inputs/FloatingLabelInputVariables';
@import 'Application/view/framework/common/form/floating-labeled-inputs/mixins/autoFillAnimations';
@import 'Application/view/components/variables';

$topSpace: 22px;
$bottomSpace: 4px;

.textInput {
    position: relative;
    padding: 0;
    margin: 0;
    font-size: $inputFontSize;

    &__withoutTransitions > div,
    &__withoutTransitions > label {
        transition: none !important; // Avoid transitions on page load
    }

    &__simpleLine {
        .input {
            padding-left: 0;
            padding-right: 0;
            border-radius: 0;
            border-left: none;
            border-top: none;
            border-right: none;

            &__withError,
            &__isValid {
                $newBorderSize: 2px;

                border-width: $newBorderSize;
                padding-bottom: $bottomSpace - ($newBorderSize - $borderSize);

                &:hover,
                &:focus {
                    border-width: $borderSize;
                    padding-bottom: $bottomSpace;
                }
            }
        }
    }

    &__slim {
        .input {
            &__mobile {
                height: 39px;
                padding: 0 $global-spacing-small;
                font-size: 18px;
            }

            &__desktop,
            &__tablet {
                height: 40px;
                padding: 0 $global-spacing-small;
                font-size: 16px;
            }

            &__withPaddingForIcon {
                padding-right: 40px;
            }
        }
    }

    &__mobile {
        .closeIcon,
        .inputIcon {
            padding: $global-spacing;
        }
    }
}

.closeIcon,
.inputIcon {
    padding: $global-spacing-small $global-spacing $global-spacing;
    position: absolute;
    top: 0;
    right: 0;
}

.closeIcon {
    color: $marktMaedels;
    font-size: 15px;
    line-height: 1.9;
    cursor: pointer;

    &__nextToInputIcon {
        padding-right: 10px;
        right: 40px;
    }

    + .inputIcon {
        padding-left: 10px;
    }

    &__highlightIcon {
        color: $silverStar;
    }
}

.inputIcon {
    font-size: 20px;
    color: $brat;
}

.input {
    @include textOverflowEllipsis;

    position: relative;
    padding: $topSpace $global-spacing $bottomSpace $global-spacing;
    margin: 0;
    font-size: inherit;
    color: $silverStar;
    border: $borderSize solid $borderColor;
    border-radius: $borderRadius;
    width: 100%;
    height: $inputFieldHeight;
    touch-action: pan-x pan-y;
    transition:
        box-shadow 500ms,
        border-color 250ms ease-in-out;

    @include autoFillAnimations;

    // some devices style inputs with type="search" to have a default background color (e.g. iOS 15)
    background-color: $sophieWhite;

    &__hasNoFocus {
        &:not(&.input__touchDevice) {
            &:hover {
                border-color: $borderColorHover;
            }
        }
    }

    &:focus {
        border-color: $borderColorFocus;
    }

    &:read-only,
    &:disabled {
        color: $marktMaedels;

        &:hover,
        &:focus {
            border-color: $marktMaedels;
        }
    }

    &[readonly] {
        background-color: $almostWhite;
        cursor: default;
        pointer-events: none;
        color: $silverStar;
    }

    &__withError {
        border-color: $borderColorError;
    }

    &__isValid {
        border-color: $borderColorValid;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:hover {
        animation: onAutoFillStart 0s;
    }

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        display: none;
    }

    &:not(:-webkit-autofill) {
        animation: onAutoFillCancel 0s;
    }

    &__mobile {
        height: $inputFieldHeightMobile;
        font-size: 18px;
        padding: $topSpace $global-spacing 10px;
    }

    &__highlightedInputField {
        border-color: $borderColorFocus;
    }

    &__withPaddingForIcon {
        padding-right: $global-spacing-xlarge;
    }
}

.separatedIconContainer {
    border-left: 1px solid $plainGrey;
    padding: 0 $global-spacing-small;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;

    &__focus {
        border-color: $borderColorFocus;
    }

    &__disabled {
        pointer-events: none;

        .separatedIcon {
            color: $dustyGrey;
        }
    }
}

.separatedIcon {
    display: flex;
    font-size: 16px;
}
