$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.container {
    &__mobile {
        .label {
            font-size: 16px;
        }

        .infoIcon {
            font-size: 12px;
            margin-left: unset;
        }
    }
}

.tooltipHeader {
    font-weight: bold;
    margin-bottom: $global-spacing-small;
}

.section {
    margin-bottom: $global-spacing-small;
}

.infoIcon {
    font-size: 14px;
    color: $plainGrey;
    cursor: help;
    margin-left: $global-spacing-xsmall;
}

.tooltipContainer {
    display: inline;
    line-height: 1;
}
