$forMobile: "true";@import 'Application/view/framework/common/settings';

.headline {
    font-size: 18px;
    font-weight: bold;
    padding: $global-spacing 0 0;
    margin-bottom: $global-spacing-small;
}

.content {
    margin-bottom: $global-spacing-xsmall;
    font-size: 16px;
    line-height: 1.3;
}

.link {
    font-size: 16px;
}
