$forMobile: "true";@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';
@import 'Icons/view/generated/icons';
@import 'Application/view/framework/common/mixins/selectNone';

$verticalPadding: 6px;
$borderSize: 1px;

.travelToggleChip {
    @include selectNone;

    height: calc(#{($verticalPadding + $borderSize) * 2} + #{$global-line-height}em);
    padding: $verticalPadding 15px;
    display: flex;
    align-items: center;
    color: $sophieWhite;
    border-radius: 20px;
    border: 1px solid $transparent;
    font-size: 14px;

    &__isActive {
        color: $frosteon;
        border: $borderSize solid $sophieWhite;
        background-color: rgba($sophieWhite, 0.1);
    }
}

.icon {
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
}

.label {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    white-space: nowrap;
}
