$forMobile: "true";@import 'Application/view/framework/common/settings';

:global {
    /* stylelint-disable no-invalid-position-at-import-rule */
    @import 'swiper/swiper';
    @import 'swiper/modules/grid';
    @import 'swiper/modules/free-mode';
    /* stylelint-enable no-invalid-position-at-import-rule */
}

.headline {
    font-size: 20px;
    line-height: 1.25;
    margin-bottom: $global-spacing;
}

.lastSearchesContainer {
    padding: $global-spacing 0 0;
    position: relative;
}

.lastSearchesSwiper {
    width: 100%;
    padding: 0 10px;
}

.lastSearchesSwiperWrapper {
    // Override margin of .swiper-wrapper
    margin-bottom: $global-spacing !important;

    // Should correspond roughly to the height for 2 items on top of each other, so that the grid always remains 2-line
    // An element has a default height of 90px. There is also a margin and a little buffer, as the elements can also become larger
    max-height: 250px;

    // A single slide can have a height of up to 140px (not fixed, but more should not be possible), so we need to increase the maximum height in this case.
    // However, we cannot set this by default, as otherwise more than 2 items will stack on top of each other if they are together smaller than the maximum height
    &__biggerSlides {
        max-height: 300px;
    }
}

.lastSearchItem {
    width: 340px;
}
