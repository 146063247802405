$forMobile: "true";@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/textOverflowEllipsis';
@import 'Application/view/framework/common/settings';

.tile {
    position: relative;
    box-shadow: none;
    height: 220px;
}

.image {
    object-fit: cover;
    background-color: $almostWhite;
    border-radius: $global-radius;
    width: 100%;
    height: 220px;
    transition: transform 250ms ease-in-out;
}

.contentWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($darkSideOfTheForce, 0.3);
    border-radius: $global-radius;
}

.content {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: $global-spacing-medium;
    font-size: 12px;
    color: $sophieWhite;
}

.text {
    @include textOverflowEllipsis;

    font-size: 20px;
    line-height: 1;
    font-weight: bold;
}

.subText {
    @include textOverflowEllipsis;

    font-size: 16px;
    font-weight: normal;
}
