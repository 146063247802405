$forMobile: "true";@import 'Application/view/generated/colors';
@import 'Application/view/generated/zIndices';
@import 'Application/view/framework/common/settings';

@mixin small-phone {
    @media screen and (width <= 360px) {
        @content;
    }

    @media screen and (height <= 700px) {
        @content;
    }
}

.splashScreenEasterDiscount {
    @include small-phone {
        transform: scale(0.85);
    }
}

.imageContainer {
    position: relative;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headline {
    color: $sophieWhite;
    text-align: center;
    font-size: 36px;
    margin-bottom: $global-spacing-medium;
}

.easterEggImage {
    width: 240px;
}

.promptContainer {
    text-align: center;
    color: $sophieWhite;
    line-height: 1;
    margin-top: $global-spacing;
}

.countDown {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    gap: $global-spacing-small;
    margin-bottom: $global-spacing-medium;
}

.button {
    margin-top: $global-spacing;
}

.appButtons {
    margin-top: $global-spacing-small;
    display: flex;
    justify-content: center;
}
