$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/search-form/components/tooltips/searchFormTooltipContainer';

.automaticCalculation {
    @include searchFormToolTipContainer;

    background-color: $sophieWhite;
    padding: $global-spacing;
    margin-top: 0;
    display: flex;

    &__mobile {
        box-shadow: none;
        flex-flow: column;
    }
}
