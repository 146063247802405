$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/textOverflowEllipsis';

.tinyResult {
    position: relative;
    border: 1px solid $marktMaedels;
    border-radius: 3px;
    background: $sophieWhite;
    transition: box-shadow 0.25s ease-out;
    display: flex;
    flex-direction: column;
    height: 100%;

    &__mouseDevice {
        &:hover {
            cursor: pointer;
            box-shadow: 0 0 5px 1px rgba($darkSideOfTheForce, 0.14);
        }
    }

    &__useHoverEffect {
        &:hover {
            .content {
                background-color: $linkWater;
            }
        }
    }
}

.imageContainer {
    position: relative;
}

.image {
    position: relative;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.pointsContainer {
    position: absolute;
    top: 7px;
    right: 10px;
    font-size: 22px;
}

.supplierLogoContainer {
    position: absolute;
    left: 10px;
    bottom: 10px;
}

.homesBadgeContainer {
    position: absolute;
    left: $global-spacing-small;
    bottom: $global-spacing-small;
}

.content {
    padding: $global-spacing-small;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @include breakpoint(medium) {
        padding: $global-spacing-smedium;
    }
}

.contentHeader {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    margin-bottom: 10px;
    row-gap: $global-spacing-xsmall;

    &__withSimplifiedRating {
        margin-bottom: 8px;
    }
}

.truncatedNameContainer {
    display: block;
    font-size: 16px;
    line-height: 1.2;
    color: $silverStar;
    max-width: 580px;
    margin-bottom: 0;
    font-weight: bold;

    &__mouseDevice {
        &:hover {
            color: $juna;
        }
    }
}

.location {
    @include textOverflowEllipsis;

    color: $silverStar;
}

.distanceCity {
    color: $silverStar;
    display: flex;
    align-items: flex-end;
    column-gap: $global-spacing-xsmall;
}

.markerIcon {
    font-size: 14px;
    line-height: 14px;
}

.starsContainer {
    display: flex;
    align-items: flex-start;
    height: 18px;
}

.rating {
    color: $silverStar;

    &__withoutRating {
        font-size: 11px;
    }

    & + .priceContainerWrapper {
        margin-top: $global-spacing-small;
    }
}

.locationInfo {
    margin-bottom: $global-spacing-smedium;
}

.price {
    transition: color 0.25s ease-out;
}

.priceDisplay {
    text-align: right;
    font-size: 18px;
    line-height: 1;
    color: $silverStar;
    padding-top: 13px;
}

.prefix {
    font-weight: normal;
    font-size: 12px;
    color: $silverStar;
}

.lastSeen {
    margin-top: 10px;
    color: $silverStar;
}

.lastSeenIcon {
    $size: 17px;

    margin-right: $global-spacing-xsmall;
    font-size: $size;
    line-height: $size;
    height: $size;
    display: inline-block;
    vertical-align: bottom;
    color: $tundora;
}

.priceContainerWrapper {
    color: $silverStar;
    margin-top: auto;
    transition: color 0.25s ease-out;
}

.priceContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.providerPriceContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: $global-spacing-xsmall;

    &__wrapPrice {
        flex-wrap: wrap;
        justify-content: flex-end;
    }
}

.providerPrice {
    font-size: 24px;
    color: $silverStar;
    line-height: 1;

    &__showSmallPrice {
        font-size: 12px;
    }
}

.effectivePriceContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: $global-spacing-xsmall;
    margin-top: $global-spacing-xsmall;
}

.additionalEffectivePrice {
    font-size: 20px;
    line-height: 23px;
}
