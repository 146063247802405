$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Offers/view/offers/common/components/details-sections/common';
@import 'Application/view/generated/colors';
@import 'Application/view/generated/assets/assets';
@import 'Application/view/framework/common/mixins/c24Sprite';

.header {
    display: flex;
    align-items: center;
    padding: $global-spacing 0 $global-spacing-smedium;
    gap: $global-spacing-small;
}

.chatMessageSophieStyle {
    display: inline-block;
    border-radius: 20px;
    position: relative;
    line-height: 20px;
    background-color: $lynxWhite;
    padding: $global-spacing-small 10px;
    font-size: 14px;

    &__noMarginBottom {
        margin-bottom: 0;
    }
}

.chatBubbleNibble {
    position: absolute;
    bottom: 0;
    left: -4px;
    width: 12px;
    height: 17px;
    background-image: url($chatBubbleSvg);
    transform: scale(-1, 1);
    background-repeat: no-repeat;
    background-position: -28px -16px;
    color: $doveGrey;
}
